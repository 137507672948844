<template>
  <div class="site-container page-contact">
    <ab-header></ab-header>

    <div class="site-banner">
      <img src="@/assets/img/website/banner-contact.png" />
    </div>

    <div class="contact-content">
      <div class="cc-hd">芯起国际物流（上海）有限公司</div>

      <div class="cc-bd">
        <div class="address">
          <div class="icon"></div>
          <div class="text">
            上海市黄浦区西藏中路18号港陆广场2102室<br />
            深圳市南山区前海深港合作区金融街1号弘毅大厦9楼<br />
            湖南省长沙市高新区文轩路27号麓谷钰园F2栋24层
          </div>
        </div>

        <div class="other">
          <div class="icon"></div>
          <div class="wechat">
            <div class="item">
              <div class="pic">
                <img src="@/assets/img/website/wechat-2.png" />
              </div>
              <div class="info">
                沈先生<br />
                +86 138 1678 4278<br />
                jason.shen@cicsc.com
              </div>
            </div>
            <div class="item">
              <div class="pic">
                <img src="@/assets/img/website/wechat-1.png" />
              </div>
              <div class="info">
                朱小姐<br />
                +86 191 2174 0157<br />
                zhu.jie@cicsc.com
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cc-map">
        <img src="@/assets/img/website/contact-pic.png" />
      </div>
    </div>

    <ab-footer></ab-footer>
  </div>
</template>

<script>
export default {
  name: 'contact',
  components: {

  },

  data () {
    return {
      
    }
  },

  computed: {

  },

  watch: {

  },

  mounted() {

  },

  methods: {
    
  }
}
</script>

<style lang="scss">
.page-contact {
  .contact-content {
    width: 1200px;
    margin: 80px auto 0;
    .cc-hd {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      color: #009fe8;
    }
    .cc-bd {
      display: flex;
      margin-top: 60px;
      .address {
        width: 500px;
        .icon {
          width: 81px;
          height: 81px;
          background: url("../../assets/img/website/contact-add.png") no-repeat;
          background-size: contain;
        }
        .text {
          margin-top: 30px;
          font-size: 20px;
          line-height: 46px;
          color: #000;
        }
      }
      .other {
        width: 700px;
        .icon {
          width: 81px;
          height: 81px;
          background: url("../../assets/img/website/contact-tel.png") no-repeat;
          background-size: contain;
        }
        .wechat {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          .item {
            display: flex;
            .pic {
              width: 130px;
              height: 130px;
              margin-right: 16px;
              img {
                width: 100%;
              }
            }
            .info {
              padding-top: 46px;
              font-size: 18px;
              line-height: 28px;
              color: #000;
            }
          }
        }
      }
    }
    .cc-map {
      margin-top: 60px;
      padding: 9px;
      border: 2px solid #80d7ff;
      font-size: 0;
      img {
        width: 1178px;
      }
    }


    // .map {
    //   flex: none;
    //   width: 580px;
    //   img {
    //     width: 100%;
    //   }
    // }
    // .text {
    //   flex: none;
    //   width: 540px;
    //   font-size: 20px;
    //   .name {
    //     margin-top: 30px;
    //     font-weight: 700;
    //     color: #009FE8;
    //   }
    //   .address {
    //     margin-top: 20px;
    //     line-height: 36px;
    //   }
    //   .wechat {
    //     display: flex;
    //     justify-content: space-around;
    //     margin-top: 40px;
    //     .item {
    //       .pic {
    //         width: 150px;
    //         height: 150px;
    //         margin: 0 auto;
    //         img {
    //           width: 100%;
    //         }
    //       }
    //       .info {
    //         margin-top: 15px;
    //         font-size: 18px;
    //         line-height: 26px;
    //         text-align: center;
    //         color: #000;
    //       }
    //     }
    //   }
    // }
  }
}
</style>
